<template>
  <div class="el_investment">
    <div class="zczy">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">产业园招商政策</h2>
          <div class="line1"></div>
          <div class="en">Industrial Park Investment Policy</div>
        </div>
        <div class="zczy-box mb40">
          <ul class="zczy-list">
            <li v-for="(item,index) in list1"
                :key="index"
                @click="toRouter('article', item)">
              <a>
                <h3 class="tit">{{item.title}}</h3>
                <div class="txt">
                  <div class="txt-l">
                    {{item.articleAbstract}}
                  </div>
                  <div class="txt-r">发布日期：{{item.releaseTime}}</div>
                </div>
              </a>
            </li>
          </ul>
          <div class="zczy-more">
            <a @click="toRouter('information',{type:20,title:'产业园招商政策',noImage:1,en:'INDUSTRIAL PARK INVESTMENT POLICY'})">更多<img src="../../../assets/images/icon-more.png" /> </a>
          </div>
        </div>
        <div class="mtit1">
          <h2 class="cn">人才城招商政策</h2>
          <div class="line1"></div>
          <div class="en">Industrial Park Investment Policy</div>
        </div>
        <div class="zczy-box">
          <ul class="zczy-list">
            <li v-for="(item,index) in list2"
                :key="index"
                @click="toRouter('article', item)">
              <a>
                <h3 class="tit">{{item.title}}</h3>
                <div class="txt">
                  <div class="txt-l">
                    {{item.articleAbstract}}
                  </div>
                  <div class="txt-r">发布日期：{{item.releaseTime}}</div>
                </div>
              </a>
            </li>
          </ul>
          <div class="zczy-more">
            <a @click="toRouter('information',{type:21,title:'人才城招商政策',noImage:1,en:'Investment promotion policy of talent City'})">更多<img src="../../../assets/images/icon-more.png" /> </a>
          </div>
        </div>

        <div class="gjrcc-btn">
          <a class="mfullbtn"
             @click="toRouter2('apply',{cn:'入驻申请',en:'Application for residence',type:1})">
            <img src="../../../assets/images/icon32.png" />入驻申请</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_investment',
      list1: [],
      list2: [],
    }
  },
  mounted () {
    this.getList1();
    this.getList2();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {};
      if (routerName === 'information') { // 党建要闻
        query = {
          type: item.type,
          title: item.title,
          en: item.en,
          noImage: item.noImage
        }
      } if (routerName === 'article') {// 去文章详情
        query = {
          id: item.id
        }
      }
      this.newRouter('/' + routerName, query)
    },
    toRouter2 (routerName, item) {
      let query = {
        ...item
      };
      this.newRouter('/' + routerName, query)
    },
    getList1 () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 20 }).then(res => {
        this.list1 = res.data.result.records;
      })
    },
    getList2 () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 21 }).then(res => {
        this.list2 = res.data.result.records;
      })
    },
  }
}
</script>
<style scoped lang="scss">
@media screen and (max-width: 1201px) {
  .txt-l {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1201px) {
  .txt-l {
    width: calc(100% - 200px);
  }
}
</style>
